import { Response } from "../../types/api";
import { Api } from "../api-store";
import { Product } from "../product";
import { FavoriteBody, ToggleFavoriteBody } from "./favorite-types";

export const FavoriteApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    favorites: builder.query<Response<Product[]>, FavoriteBody | undefined>({
      providesTags: (result, error, params) => [
        { type: "favorites", id: params?.type },
      ],

      query: ({ ...params }) => ({
        url: `/${params.type}`,
        method: "GET",
        params: {
          ...params,
          favorite: 1,
        },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.type}`;
      },
      merge: (
        currentCache: Response<Product[]>,
        newItems: Response<Product[]>
      ) => {
        if (
          currentCache.response.data?.length + newItems.response.data?.length <=
          (newItems.response.meta?.total || 0)
        ) {
          currentCache.response.data.push(...newItems.response.data);
        }
        return currentCache as Response<Product[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    favoritesNumber: builder.query<
      Response<Product[]>,
      FavoriteBody | undefined
    >({
      providesTags: (result, error, params) => [{ type: "favorites-number" }],

      query: ({ ...params }) => ({
        url: `/${params.type}`,
        method: "GET",
        params: {
          ...params,
          favorite: 1,
        },
        cache: "no-cache",
      }),
    }),

    toggleFavorite: builder.mutation<Response<{}>, ToggleFavoriteBody>({
      invalidatesTags: ["favorites", "branches", "favorites-number"],
      query: ({ ...data }) => ({
        url: `/favorites`,
        method: "POST",
        body: JSON.stringify(data),
      }),

      // async onQueryStarted(
      //   { item_id },
      //   { dispatch, queryFulfilled, getState }
      // ) {
      //   try {
      //     await queryFulfilled;

      //     const state = getState() as RootState;

      //     const cachedQueries = SearchApi.endpoints.products.select({})(state);

      //     dispatch(
      //       SearchApi.util.updateQueryData(
      //         "serviceSearch",
      //         cachedQueries?.originalArgs as ProductsQueryParams,
      //         (draft) => {
      //           draft.response.data = draft.response.data?.map(
      //             (product: Product) => {
      //               if (product.id === item_id) {
      //                 return {
      //                   ...product,
      //                   favorite: product.favorite === 1 ? 0 : 1,
      //                 };
      //               }
      //               return product;
      //             }
      //           );
      //         }
      //       )
      //     );

      //   } catch (error) {
      //     console.error("Error in removing product from cache:", error);
      //   }
      // },
    }),
  }),
});

export const {
  useToggleFavoriteMutation,
  useFavoritesQuery,
  useFavoritesNumberQuery,
} = FavoriteApi;
