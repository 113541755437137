import { useState } from "react";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { useFavoritesNumberQuery, useFavoritesQuery } from "./favorite-api";
import { FavoriteBody } from "./favorite-types";
import { useAuthSlice } from "~/old-app/redux/auth-slice";

export const useFavorites = (props?: FavoriteBody) => {
  const { selectedService } = useConfigSlice();
  const { accessToken } = useAuthSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useFavoritesQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { skip: !selectedService || !props?.type || !accessToken }
  );

  const favorites = data?.response.data || [];
  const total = data?.response.meta?.total || 0;

  const hasMore = total !== favorites.length;
  const fetchNextPage = () => setPage((prev) => prev + 1);

  return {
    favorites,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
  };
};
export const useFavoritesNumber = (props?: FavoriteBody) => {
  const { selectedService } = useConfigSlice();
  const { accessToken } = useAuthSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useFavoritesNumberQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { skip: !selectedService || !props?.type || !accessToken }
  );

  const favorites = data?.response.data || [];
  const total = data?.response.meta?.total || 0;

  const hasMore = total !== favorites.length;
  const fetchNextPage = () => setPage((prev) => prev + 1);

  return {
    favorites,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
  };
};

// export const useFaq = (id?: string) => {
//   const { data, isLoading } = useFaqQuery(id, { skip: !id });
//   return {
//     faq: data,
//     isLoading,
//   };
// };
// export const useFaqByPageName = (
//   pageName?: string,
//   courseInstanceId?: string
// ) => {
//   const { data, isLoading } = useFaqByPageNameQuery(
//     { pageName, courseInstanceId },
//     { skip: !pageName }
//   );
//   return {
//     faq: data,
//     isLoading,
//   };
// };
